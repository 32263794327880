var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('jf-layout',[_c('template',{slot:"head"},[(_vm.isAuth('platform-config:awarddeductiontask:save'))?_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-circle-plus"},on:{"click":function($event){return _vm.openAdd('新增任务模板')}}},[_vm._v("新增任务模板")]):_vm._e(),_c('div',{staticClass:"hint"},[_c('div',[_vm._v("已选中"),_c('span',{staticClass:"c-warning"},[_vm._v(_vm._s(_vm.selectArr.length))]),_vm._v("条")]),_c('div',[(_vm.isAuth('platform-config:awarddeductiontask:delete')&&_vm.isAuth('platform-config:awarddeductiontask:delete'))?_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete","disabled":_vm.selectArr.length<=0},on:{"click":function($event){return _vm.delRow()}}},[_vm._v("删除")]):_vm._e()],1)])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"slot":"body","border":"","data":_vm.list},on:{"selection-change":_vm.getSelect},slot:"body"},[_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"align":"center","prop":"taskName","label":"任务名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"data","label":"奖分指标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.awardLimit)?_c('div',[_vm._v(_vm._s(row.awardLimit)+"/"+_vm._s(row.awardCycle==0?'周':row.awardCycle==1?'月':row.awardCycle==2?'季':row.awardCycle==3?'年':''))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"data","label":"扣分指标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.deductionLimit)?_c('div',[_vm._v(_vm._s(row.deductionLimit)+"/"+_vm._s(row.deductionCycle==0?'周':row.deductionCycle==1?'月':row.deductionCycle==2?'季':row.deductionCycle==3?'年':''))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"data","label":"频次指标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.awardTimeLimit)?_c('div',[_vm._v(_vm._s(row.awardTimeLimit)+"/"+_vm._s(row.awardTimeCycle==0?'周':row.awardTimeCycle==1?'月':row.awardTimeCycle==2?'季':row.awardTimeCycle==3?'年':''))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"data","label":"比例指标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.awardRatio)?_c('div',[_vm._v(_vm._s(row.awardRatio)+"%/月")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"createDate","label":"创建时间"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","icon":"el-icon-view"},on:{"click":function($event){return _vm.openAdd('查看任务模板',row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"size":"mini","icon":"el-icon-edit"},on:{"click":function($event){return _vm.openAdd('编辑任务模板',row)}}},[_vm._v("编辑")]),_c('el-button',{staticClass:"c-danger-imp",attrs:{"size":"mini","icon":"el-icon-delete","type":"danger"},on:{"click":function($event){return _vm.delRow(row)}}},[_vm._v("删除")])]}}])})],1),_c('jf-page',{attrs:{"slot":"foot","total":_vm.total,"page":_vm.pageParam.page,"limit":_vm.pageParam.limit},on:{"update:page":function($event){return _vm.$set(_vm.pageParam, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.pageParam, "limit", $event)},"change":function($event){return _vm.getList('reset')}},slot:"foot"})],2),_c('Add',{attrs:{"show":_vm.show,"id":_vm.id,"title":_vm.addTitle},on:{"update:show":function($event){_vm.show=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }