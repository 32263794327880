<template>
	<div>
  <jf-layout>
    <template slot="head">
      <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:awarddeductiontask:save')" @click="openAdd('新增任务模板')">新增任务模板</el-button>
	  <div class="hint">
	  	<div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
	  	<div>
	  		<el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:awarddeductiontask:delete')&&isAuth('platform-config:awarddeductiontask:delete')" :disabled="selectArr.length<=0"
	  			@click="delRow()">删除</el-button>
	  	</div>
	  </div>
    </template>

    <el-table slot="body" border :data="list" v-loading="tableLoading" @selection-change="getSelect">
      <el-table-column align="center" type="selection" width="50"></el-table-column>
      <el-table-column align="center" prop="taskName" label="任务名称" /></el-table-column>
      <el-table-column align="center" prop="data" label="奖分指标" >
		  <template slot-scope="{row}">
			  <div v-if="row.awardLimit">{{row.awardLimit}}/{{row.awardCycle==0?'周':row.awardCycle==1?'月':row.awardCycle==2?'季':row.awardCycle==3?'年':''}}</div>
		  </template>
	  </el-table-column>
      <el-table-column align="center" prop="data" label="扣分指标" >
		  <template slot-scope="{row}">
		  			 <div v-if="row.deductionLimit">{{row.deductionLimit}}/{{row.deductionCycle==0?'周':row.deductionCycle==1?'月':row.deductionCycle==2?'季':row.deductionCycle==3?'年':''}}</div> 
		  </template>
	  </el-table-column>
      <el-table-column align="center" prop="data" label="频次指标" >
		  <template slot-scope="{row}">
			  <div v-if="row.awardTimeLimit">{{row.awardTimeLimit}}/{{row.awardTimeCycle==0?'周':row.awardTimeCycle==1?'月':row.awardTimeCycle==2?'季':row.awardTimeCycle==3?'年':''}}</div>
		  </template>
	  </el-table-column>
      <el-table-column align="center" prop="data" label="比例指标" >
		  <template slot-scope="{row}">
			  <div v-if="row.awardRatio">{{row.awardRatio}}%/月</div>
		  </template>
	  </el-table-column>
      <el-table-column align="center" prop="createDate" label="创建时间" />
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{row}">
          <el-button size="mini" icon="el-icon-view" @click="openAdd('查看任务模板',row)">查看</el-button>
          <el-button size="mini" icon="el-icon-edit" @click="openAdd('编辑任务模板',row)">编辑</el-button>
          <el-button size="mini" icon="el-icon-delete" type="danger" class="c-danger-imp" @click="delRow(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" @change="getList('reset')"/>
  </jf-layout>
   <Add :show.sync="show" :id="id" :title="addTitle"/>
   </div>
</template>

<script>
import Add from "./add";
export default {
  components: { Add },
  data() {
    return {
      show: false,
	  pageParam:{
		  page:1,
		  limit:10,
	  },
	  tableLoading:false,
	  selectArr:[],
	  total:0,
      list: [],
	  id:null,
	  addTitle:null,
    };
  },
	created() {
		this.getList()
	},
	methods:{
		// 获取列表
		async getList(){
			this.tableLoading=true;
			let res=await this.$get('/platform-config/awarddeductiontask/page',this.pageParam);
			this.tableLoading=false;
			if(res&&res.code==0){
				this.list=res.data.list;
				this.total=res.data.total
			}
		},
		// 打开新增  编辑 的弹窗
		openAdd(title,row){
			this.addTitle=title;
			this.id=row?row.id:null;
			this.show=true;
		},
		// 选择table
		getSelect(e) {
			this.selectArr = e
		},
		// 删除 table
		delRow(row) {
			let ids = []
			if (row) {
				ids = [row.id]
			} else {
				this.selectArr.map((v) => {
					ids.push(v.id)
				})
			}
			this.$confirm('确定执行删除操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				this.tableLoading = true;
				let res = await this.$del(`/platform-config/awarddeductiontask/deleteByIds`,ids);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.$message.success("删除成功！")
					this.getList()
				}
			}).catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
	.hint {
		display: flex;
		justify-content: space-between;
		background: #FFF4E3;
		border-radius: 5px;
		padding: 0 17px;
		margin-top: 15px;
	}
</style>

