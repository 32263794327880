<template>
	<el-dialog v-dialogDrag :title="title" :visible.sync="visible" width="1000px" @before-close="close">
		<el-form label-width="100px" ref="form"  :model="formParam" :rules="rules" :disabled="title=='查看任务模板'">
			<el-form-item label="任务名称" prop="taskName">
				<el-input v-model="formParam.taskName" maxLength="30"></el-input>
			</el-form-item>
			<el-form-item label="任务指标">
				<ul>
					<li>
						<el-checkbox v-model.number="formParam.isAward" :true-label="1" :false-label="0"
							class="checkbox">奖分任务
						</el-checkbox>
						<div class="flexbox">
							<div>
								<span> 考核周期</span>
								<el-select v-model.number="formParam.isAwardTaskDTO.awardCycle" placeholder="请选择">
									<el-option label="周 (4周/月)" :value="0"></el-option>
									<el-option label="月" :value="1"></el-option>
								</el-select>
							</div>
							<div>
								<span>奖分下限 </span>
								<el-input v-model.number="formParam.isAwardTaskDTO.awardLimit" class="input"></el-input>
							</div>
						</div>
						<div class="flexbox">
							<div>
								<span> 达标奖分</span>
								<el-input v-model.number="formParam.isAwardTaskDTO.awardScore" class="input"></el-input>
							</div>
							<div>
								<span> 未达标扣分 </span>
								<div class="input">差额扣分</div>
							</div>
						</div>
					</li>
					<li>
						<el-checkbox v-model="formParam.isDeduction" :true-label="1" :false-label="0" class="checkbox">
							扣分任务</el-checkbox>
						<div class="flexbox">
							<div>
								<span> 考核周期</span>
								<el-select v-model.number="formParam.isDeductionTaskDTO.deductionCycle"
									placeholder="请选择">
									<el-option label="周 (4周/月)" :value="0"></el-option>
									<el-option label="月" :value="1"></el-option>
								</el-select>
							</div>
							<div>
								<span>扣分下限 </span>
								<el-input class="input" v-model.number="formParam.isDeductionTaskDTO.deductionLimit">
								</el-input>
							</div>
						</div>
						<div class="flexbox">
							<div>
								<span> 达标奖分</span>
								<el-input class="input" v-model.number="formParam.isDeductionTaskDTO.deductionScore">
								</el-input>
							</div>
							<div>
								<span> 未达标扣分 </span>
								<div class="input">差额扣分</div>
							</div>
						</div>
					</li>
					<li>
						<el-radio v-model="formParam.awardTimeType" :label="1">奖扣人次任务</el-radio>
						<el-radio v-model="formParam.awardTimeType" :label="2">奖扣事件数任务</el-radio>
						<div class="flexbox">
							<div>
								<span> 考核周期</span>
								<el-select v-model="formParam.isAwardTimeTypeTaskDTO.awardTimeCycle" placeholder="请选择">
									<el-option label="日" :value="3"></el-option>
									<el-option label="周 (4周/月)" :value="0"></el-option>
									<el-option label="月" :value="1"></el-option>
								</el-select>
							</div>
						</div>
						<div class="flexbox">

							<div>
								<span> 人次下限</span>
								<el-input class="input" v-model="formParam.isAwardTimeTypeTaskDTO.awardTimeLimit">
								</el-input>
							</div>
							<div>
								<span>频次类型 </span>
								<div class="input">人次</div>
							</div>
						</div>
						<div class="flexbox">
							<div>
								<span> 达标奖分</span>
								<el-input class="input" v-model="formParam.isAwardTimeTypeTaskDTO.awardTimeScore">
								</el-input>
							</div>
							<div>
								<span> 未达标扣分 </span>
								<el-input class="input" v-model="formParam.isAwardTimeTypeTaskDTO.awardTimeUndone">
								</el-input>
							</div>
						</div>
					</li>
					<li>
						<el-checkbox class="checkbox" v-model="formParam.isAwardRatio" :true-label="1" :false-label="0">奖扣比例任务</el-checkbox>
						<div class="flexbox">
							<div>
								<span> 考核周期</span>
								<div class="input">月</div>
							</div>
							<div>
								<span>奖扣比例%</span>
								<el-input class="input" v-model="formParam.isAwardRatioCycleTaskDTO.awardRatio">
								</el-input>
							</div>
						</div>
						<div class="flexbox">
							<div>
								<span> 达标奖分</span>
								<el-input class="input" v-model="formParam.isAwardRatioCycleTaskDTO.awardRatioScore">
								</el-input>
							</div>
							<div>
								<span> 未达标扣分 </span>
								<div class="input">差额扣分</div>
							</div>
						</div>
					</li>
				</ul>
			</el-form-item>

		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {
					isAward: 0,
					isAwardRatio: 0,
					isAwardRatio: 0,
					awardTimeType: 0,
					isDeduction: 0,
					isAwardRatioCycleTaskDTO: {
						awardUndone: 0,
					},
					isAwardTaskDTO: {
						awardUndone: 0,
					},
					isAwardTimeTypeTaskDTO: {},
					isDeductionTaskDTO: {
						awardUndone: 0,
					}
				},
				period: [{
						label: "周",
						value: 0
					},
					{
						label: "月",
						value: 1
					},
					{
						label: "季",
						value: 2
					},
					{
						label: "日",
						value: 3
					}
				],
				rules: {
					taskName: [{
						required: true,
						message: '请输入任务名称',
						trigger: 'blur'
					}]
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			id: {
				type: String | Number,
				default: null,
			},
			title: {
				type: String,
				default: null,
			},
		},
		computed: {
			visible: {
				get() {
					if (this.id) {
						this.getDetail()
					} else {
						this.formParam = {
							isAward: 0,
							isAwardRatio: 0,
							isAwardRatio: 0,
							awardTimeType: 0,
							isDeduction: 0,
							isAwardRatioCycleTaskDTO: {
								awardUndone: 0,
							},
							isAwardTaskDTO: {
								awardUndone: 0,
							},
							isAwardTimeTypeTaskDTO: {},
							isDeductionTaskDTO: {
								awardUndone: 0,
							}
						}
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {
			console.log(this.show, this.visible);
		},
		mounted() {},
		methods: {
			// 确定提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if(this.formParam.isAward==0&&this.formParam.isDeduction==0&&this.formParam.awardTimeType==0&&this.formParam.isAwardRatio==0){
							this.$message.warning("请选择任务指标！");
							return
						}
						this.formParam.isAwardTaskDTO.awardUndone=0;
						this.formParam.isDeductionTaskDTO.deductionUndone=0;
						this.formParam.isAwardRatioCycleTaskDTO.awardRatioCycle=0;
						this.formParam.isAwardRatioCycleTaskDTO.awardRatioUndone=0;
						this.formParam.isAwardRatioCycleTaskDTO.awardRatioLimit=0;
						this.btnLoading = true
						if (this.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 新增
			async save() {
				let res = await this.$post('/platform-config/awarddeductiontask', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.close()
					this.$parent.getList()
				}
			},
			// 修改
			async update() {
				let res = await this.$put('/platform-config/awarddeductiontask', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("修改成功！");
					this.close()
					this.$parent.getList()
				}
			},
			// 获取详情
			async getDetail() {
				let res = await this.$get(`/platform-config/awarddeductiontask/${this.id}`);
				if (res && res.code == 0) {
					// this.formParam = res.data;
					let param = {
						isAwardTaskDTO: {},
						isDeductionTaskDTO: {},
						isAwardTimeTypeTaskDTO: {},
						isAwardRatioCycleTaskDTO: {}
					}
					param.id=res.data.id
					param.taskName = res.data.taskName;
					param.isAward = res.data.isAward;
					param.isDeduction = res.data.isDeduction;
					param.awardTimeType = res.data.awardTimeType;
					param.isAwardRatio = res.data.isAwardRatio;
					param.isAwardTaskDTO.awardCycle = res.data.awardCycle
					param.isAwardTaskDTO.awardLimit = res.data.awardLimit
					param.isAwardTaskDTO.awardScore = res.data.awardScore
					param.isDeductionTaskDTO.deductionCycle = res.data.deductionCycle
					param.isDeductionTaskDTO.deductionLimit = res.data.deductionLimit
					param.isDeductionTaskDTO.deductionScore = res.data.deductionScore
					param.isAwardTimeTypeTaskDTO.awardTimeCycle = res.data.awardTimeCycle
					param.isAwardTimeTypeTaskDTO.awardTimeScore = res.data.awardTimeScore
					param.isAwardTimeTypeTaskDTO.awardTimeUndone = res.data.awardTimeUndone
					param.isAwardTimeTypeTaskDTO.awardTimeLimit=res.data.awardTimeLimit
					param.isAwardRatioCycleTaskDTO.awardRatio = res.data.awardRatio
					param.isAwardRatioCycleTaskDTO.awardRatioScore = res.data.awardRatioScore
					this.formParam = param
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.form{
		margin-top: 100px;
	}
	ul {
		list-style: none;
		padding: 20px 0 0 0;

		li {
			border-bottom: 1px solid #eee;
			padding-bottom: 25px;

			.checkbox {
				line-height: 50px;
				margin-bottom: 10px;
			}
		}

		.flexbox {
			display: flex;
			padding-left: 22px;
			margin-bottom: 20px;

			>div {
				flex: 1;
				display: flex;

				>span {
					width: 100px;
					display: inline-block;
				}

				.input {
					display: inline-block;
					width: 250px;
				}
			}
		}
	}
</style>
